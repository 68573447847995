<template>
  <!-- <v-card :height="getHeight" > -->
  <v-navigation-drawer
    :height="'100vh'"
    color="#e9eef3"
    :right="false"
    width="100%"
    permanent
  >
    <v-list dense nav v-for="(item, index) in items" :key="index">
      <v-list-item v-if="!item.submenus" link :to="item.link">
        <v-list-item-icon>
          <v-icon> fa-minus-square </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group prepend-icon="fa-minus-square" v-else>
        <template v-slot:activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>

        <div v-for="(subItem, index) in item.submenus" :key="index">
          <v-list-item link :to="subItem.link">
            <v-list-item-icon class="pl-4">
              <v-icon>fa-minus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-group>
    </v-list>

    <template v-slot:append>
      <div>
        <v-btn @click="logout" color="error" block>Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
  <!-- </v-card> -->
</template>

<script>
import * as slug from "./config/slug.js";
export default {
  beforeMount() {},
  computed: {
    getHeight() {
      let height = screen.height;
      return height - 145 + "px";
    },
  },
  data() {
    return {
      items: [
        {
          title: "Home",
          icon: "fa fa-home",
          link: slug.HOME,
          // subList: [

          //   {
          //     title: "Important Links",
          //     icon: "fa-tasks",
          //     link: slug.IMPORTANT_LINKS,
          //   },
          // ],
        },
        {
          title: "Settings",
          icon: "fa fa-settings",
          link: "/settings",
          submenus: [
            {
              title: "Class",
              icon: "fa-tasks",
              link: slug.CLASS,
            },
            {
              title: "Group",
              icon: "fa-tasks",
              link: slug.GROUP,
            },
            {
              title: "Subject",
              icon: "fa-tasks",
              link: slug.SUBJECT,
            },
          ],
        },
      ],
      right: null,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      window.location.reload();
    },
    menuClickHandler() {},
  },
};
</script>

<style scoped>
.selected {
  background-color: rgba(138, 150, 255, 0.438);
}
</style>
