<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row>
        <v-col cols="12" class="">
          <v-row class="w-50 mx-auto card pa-8 my-4">
            <!-- Select Class -->
            <v-col cols="12" v-if="!updateMode">
              <v-autocomplete
                outlined
                v-model="formData.classId"
                :items="classDataArray"
                item-text="className"
                item-value="classId"
                label="Class Name"
                placeholder="Select Class"
                class="required"
              ></v-autocomplete>
            </v-col>
            <!-- Group Name -->
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="formData.groupName"
                label="Group Name"
                placeholder="Enter Group Name"
                class="required"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              v-if="instituteInfo.feeTakingType === 'GROUP_WISE'"
            >
              <v-text-field
                outlined
                type="Number"
                v-model="formData.groupAdmissionFee"
                label="Group Admission Fee"
                placeholder="Enter Admission Fee for this Group"
                class="required"
              ></v-text-field>
            </v-col>
            <!-- Radio -->
            <v-col cols="12">
              <v-radio-group
                v-model="formData.isGroupGeneral"
                row
                label="Group Type"
              >
                <v-radio label="General Group" :value="true"></v-radio>
                <v-radio label="Subjective Group" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="formData.maxNumberOfSubjectSelectable"
                label="Maximum Number Of Subject"
                placeholder="Enter Number"
                type="Number"
                class="required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="primary" @click="saveBtnHandler">{{
                updateMode ? "Update" : "Save"
              }}</v-btn>
              <v-btn color="error" @click="clear">Clear</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Data Table -->
      <v-row>
        <v-col cols="6" class="mx-auto card pa-8 mt-12">
          <v-card-title>Select Class to see the created groups </v-card-title>
          <v-autocomplete
            outlined
            v-model="classId"
            :items="classDataArray"
            item-text="className"
            item-value="classId"
            label="Select Class to see Groups"
            placeholder="Select Class"
            class="required"
          ></v-autocomplete>
          <v-btn color="primary" @click="getDataArray()" :disabled="!classId"
            >Search</v-btn
          >
        </v-col>
        <v-col cols="12">
          <data-table
            :tableHeaders="headerArray"
            :tableData="dataArray"
            @deleteHandler="deleteHandler"
            @editHandler="editHandler"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getUserInfo();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      updateMode: false,
      classDataArray: [],
      classId: null,
      dataArray: [],
      headerArray: [
        { text: "Id", value: "groupId", align: "center" },
        { text: "Group Name", value: "groupName", align: "center" },
        {
          text: "Group Admission Fee",
          value: "groupAdmissionFee",
          align: "center",
        },
        { text: "General Group", value: "isGroupGeneral", align: "center" },
        {
          text: "Maximum Number Of Subject",
          value: "maxNumberOfSubjectSelectable",
          align: "center",
        },
        { text: "", value: "actions", align: "center" },
      ],
      formData: {
        classId: "",
        groupName: "",
        groupAdmissionFee: 0,
        isGroupGeneral: true,
        maxNumberOfSubjectSelectable: 0,
      },
      initFormData: {
        classId: "",
        groupName: "",
        groupAdmissionFee: 0,
        isGroupGeneral: true,
        maxNumberOfSubjectSelectable: 0,
      },
      instituteInfo: {},
    };
  },
  methods: {
    editHandler(item) {
      this.formData = item;
      this.updateMode = true;
      window.scrollTo(0, 0);
    },
    deleteHandler(item) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.DELETE_GROUP + item.groupId)
          .then((res) => {
            this.dataArray = this.dataArray.filter(
              (e) => e.groupId != item.groupId
            );
            alert("deleted");
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              alert();
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    getDataArray() {
      this.loadingState = true;
      this.$http
        .get(
          api.GROUP_NAMES +
            "/" +
            this.instituteInfo.instituteId +
            "?classId=" +
            this.classId
        )
        .then((res) => {
          this.dataArray = res.data.payLoad;
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getClassDataArray() {
      this.loadingState = true;
      this.$http
        .get(api.CLASS_NAMES + "/" + this.instituteInfo.instituteId)
        .then((res) => {
          this.classDataArray = res.data.payLoad;
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getInstituteInfo(customInstituteId) {
      this.loadingState = true;
      this.$http
        .get(`${api.GET_INSTITUTE_INFO}?customInstituteId=${customInstituteId}`)
        .then((res) => {
          this.instituteInfo = res.data.payLoad;
          this.getClassDataArray();

          console.log(res.data.payLoad);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getUserInfo() {
      this.$http
        .get(api.GET_USER_INFO)
        .then((res) => {
          this.getInstituteInfo(res.data.payLoad.customInstituteId);
          console.log(this.instituteInfo);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
    clear() {
      this.formData = { ...this.initFormData };
      this.updateMode = false;
    },
    saveBtnHandler() {
      this.updateMode ? this.updateData() : this.createData();
    },
    updateData() {
      this.loadingState = true;
      delete this.formData.classId;
      this.$http
        .put(api.UPDATE_GROUP, {
          ...this.formData,
        })
        .then((res) => {
          alert("Updated");
          // this.clear();
          console.log(this.formData);
          console.log(res);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    createData() {
      this.loadingState = true;
      let classId = this.formData.classId;
      delete this.formData.classId;
      this.$http
        .post(api.CREATE_GROUP + "/" + classId, {
          ...this.formData,
        })
        .then((res) => {
          alert("created");
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 424) {
            alert(error.response.data.message);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },

    editInstituteHandler() {},
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
