<template>
  <v-app class="">
    <loader v-if="loadingState" />
    <div v-else>
      <login v-if="!isAuthenticated" />

      <div style="display: flex" v-else>
        <div class="sidebar">
          <Drawer class="drawer" />
        </div>
        <div class="content">
          <Menubar />
          <v-container>
            <router-view></router-view>
          </v-container>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loader from "./components/loader/loader.vue";
import Drawer from "./drawer";
import Menubar from "./menubar.vue";
import Login from "./pages/login.vue";
import { GET_ALL_STUDENTS } from "./config/api.js";
export default {
  name: "App",

  beforeMount() {
    this.authenticated();
  },
  components: {
    Drawer,
    Menubar,
    Login,
    Loader,
  },

  data: () => ({
    loadingState: false,
    isAuthenticated: false,
  }),

  methods: {
    authenticated() {
          console.log("Res==>",GET_ALL_STUDENTS);

      this.loadingState = true;
      this.$http
        .get(GET_ALL_STUDENTS)
        .then((res) => {
          this.isAuthenticated = true;
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
};
</script>
<style scoped>
@import url("./css/basicStyle.css");
@import url("./css/formStyle.css");
.sidebar {
  width: 20%;
}
.drawer {
  top: 0px;
  position: sticky;
}
.content {
  width: 80%;
}
</style>
