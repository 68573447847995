<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row>
        <v-col cols="12" class="">
          <v-row class="w-50 mx-auto card pa-8 my-4">
            <!-- Select Class -->
            <v-col cols="12" v-if="!updateMode">
              <v-autocomplete
                outlined
                v-model="formData.classId"
                :items="classDataArray"
                item-text="className"
                item-value="classId"
                label="Class Name"
                placeholder="Select Class"
                class="required"
                @change="getGroupDataArray(true)"
              ></v-autocomplete>
            </v-col>
            <!-- Group -->
            <v-col cols="12">
              <v-autocomplete
                outlined
                v-model="formData.groupId"
                :items="groupDataArrayForForm"
                item-text="groupName"
                item-value="groupId"
                label="Select Group to see Subjects"
                placeholder="Select Group"
                class="required"
              ></v-autocomplete>
            </v-col>
            <!-- Subject Name -->
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="formData.subjectName"
                label="Subject Name"
                placeholder="Enter Subject Name"
                class="required"
              ></v-text-field>
            </v-col>
            <!-- Buttons -->
            <v-col cols="12" class="text-center">
              <v-btn color="primary" @click="saveBtnHandler">{{
                updateMode ? "Update" : "Save"
              }}</v-btn>
              <v-btn color="error" @click="clear">Clear</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Data Table -->
      <v-row>
        <v-col cols="6" class="mx-auto card pa-8 mt-12">
          <v-card-title
            >Select Class and Group to see created subjects</v-card-title
          >
          <v-row>
            <!-- Class -->
            <v-col cols="12">
              <v-autocomplete
                outlined
                v-model="classId"
                :items="classDataArray"
                item-text="className"
                item-value="classId"
                label="Select Class to see Groups"
                placeholder="Select Class"
                class="required"
                @change="getGroupDataArray(false)"
              ></v-autocomplete>
            </v-col>
            <!-- Group -->
            <v-col cols="12">
              <v-autocomplete
                :disabled="!groupDataArray.length"
                outlined
                v-model="groupId"
                :items="groupDataArray"
                item-text="groupName"
                item-value="groupId"
                label="Select Group to see Subjects"
                placeholder="Select Group"
                class="required"
              ></v-autocomplete>
              <v-btn
                color="primary"
                @click="getDataArray()"
                :disabled="!classId || !groupId"
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <data-table
            :tableHeaders="headerArray"
            :tableData="dataArray"
            @deleteHandler="deleteHandler"
            @editHandler="editHandler"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getUserInfo();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      updateMode: false,
      classDataArray: [],
      groupDataArray: [],
      groupDataArrayForForm: [],
      classId: null,
      groupId: null,
      dataArray: [],
      headerArray: [
        { text: "Id", value: "subjectId", align: "center" },
        { text: "Subject Name", value: "subjectName", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      formData: {
        classId: "",
        groupId: "",
        subjectName: "",
      },
      initFormData: {
        classId: "",
        groupId: "",
        subjectName: "",
      },
      instituteInfo: {},
    };
  },
  methods: {
    editHandler(item) {
      this.formData = item;
      this.updateMode = true;
      window.scrollTo(0, 0);
    },
    deleteHandler(item) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.DELETE_SUBJECT + item.subjectId)
          .then((res) => {
            this.dataArray = this.dataArray.filter(
              (e) => e.subjectId != item.subjectId
            );
            alert("deleted");
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              alert();
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    getDataArray() {
      this.loadingState = true;
      this.$http
        .get(
          api.SUBJECT_NAMES +
            "/" +
            this.instituteInfo.instituteId +
            "?groupId=" +
            this.groupId
        )
        .then((res) => {
          this.dataArray = res.data.payLoad;
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getClassDataArray() {
      this.loadingState = true;
      this.$http
        .get(api.CLASS_NAMES + "/" + this.instituteInfo.instituteId)
        .then((res) => {
          this.classDataArray = res.data.payLoad;
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getGroupDataArray(forForm = false) {
      this.loadingState = true;
      let classId = forForm ? this.formData.classId : this.classId;
      console.log("forFrom==>", forForm, "classId==>", classId);
      this.$http
        .get(
          api.GROUP_NAMES +
            "/" +
            this.instituteInfo.instituteId +
            "?classId=" +
            classId
        )
        .then((res) => {
          forForm
            ? (this.groupDataArrayForForm = res.data.payLoad)
            : (this.groupDataArray = res.data.payLoad);
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getUserInfo() {
      this.$http
        .get(api.GET_USER_INFO)
        .then((res) => {
          this.instituteInfo = res.data.payLoad;
          this.getClassDataArray();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
    clear() {
      this.formData = { ...this.initFormData };
      this.updateMode = false;
    },
    saveBtnHandler() {
      this.updateMode ? this.updateData() : this.createData();
    },
    updateData() {
      this.loadingState = true;
      delete this.formData.classId;
      this.$http
        .put(api.UPDATE_GROUP + "/" + this.formData.groupId, {
          ...this.formData,
        })
        .then((res) => {
          alert("Updated");
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    createData() {
      this.loadingState = true;
      let groupId = this.formData.groupId;
      delete this.formData.classId;
      delete this.formData.groupId;
      this.$http
        .post(api.CREATE_SUBJECT + "/" + groupId, {
          ...this.formData,
        })
        .then((res) => {
          alert("created");
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
