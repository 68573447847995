<template>
  <div>
    <loader v-if="loadingState" />
    <v-row v-else>
      <v-col cols="12" class="">
        <v-row class="w-50 mx-auto card pa-8 my-4">
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="formData.className"
              label="Class Name"
              placeholder="Enter Class Name"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="instituteInfo.feeTakingType === 'CLASS_WISE'">
            <v-text-field
              outlined
              type="Number"
              v-model="formData.classAdmissionFee"
              label="Class Admission Fee"
              placeholder="Enter Admission Fee for this class"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn color="primary" @click="saveBtnHandler">{{
              updateMode ? "Update" : "Save"
            }}</v-btn>
            <v-btn color="error" @click="clear">Clear</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <data-table
          :tableHeaders="headerArray"
          :tableData="dataArray"
          @deleteHandler="deleteHandler"
          @editHandler="editHandler"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getUserInfo();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      updateMode: false,
      dataArray: [],
      headerArray: [
        { text: "Id", value: "classId", align: "center" },
        { text: "Class Name", value: "className", align: "center" },
        {
          text: "Class Admission Fee",
          value: "classAdmissionFee",
          align: "center",
        },
        { text: "", value: "actions", align: "center" },
      ],
      formData: {
        className: "",
        classAdmissionFee: 0,
      },
      initFormData: {
        className: "",
        classAdmissionFee: 0,
      },
      instituteInfo: {},
    };
  },
  methods: {
    editHandler(item) {
      this.formData = item;
      this.updateMode = true;
      window.scrollTo(0, 0);
    },
    deleteHandler(item) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.DELETE_CLASS + item.classId)
          .then((res) => {
            this.dataArray = this.dataArray.filter(
              (e) => e.classId != item.classId
            );
            alert("deleted");
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              alert();
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    getDataArray() {
      this.loadingState = true;
      this.$http
        .get(api.CLASS_NAMES + "/" + this.instituteInfo.instituteId)
        .then((res) => {
          this.dataArray = res.data.payLoad;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getInstituteInfo(customInstituteId) {
      this.loadingState = true;
      this.$http
        .get(`${api.GET_INSTITUTE_INFO}?customInstituteId=${customInstituteId}`)
        .then((res) => {
          this.instituteInfo = res.data.payLoad;
          this.getDataArray();

          console.log(res.data.payLoad);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getUserInfo() {
      this.$http
        .get(api.GET_USER_INFO)
        .then((res) => {
          this.getInstituteInfo(res.data.payLoad.customInstituteId);
          console.log(this.instituteInfo);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
    clear() {
      this.formData = { ...this.initFormData };
      this.updateMode = false;
    },
    saveBtnHandler() {
      this.updateMode ? this.updateData() : this.createData();
    },
    updateData() {
      this.loadingState = true;
      this.$http
        .put(api.UPDATE_CLASS, {
          ...this.formData,
        })
        .then((res) => {
          alert("Updated");
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    createData() {
      this.loadingState = true;
      this.$http
        .post(api.CREATE_CLASS, {
          ...this.formData,
        })
        .then((res) => {
          alert("created");
          this.dataArray.push(res.data.payLoad);
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
