import Vue from "vue";
import VueRouter from "vue-router";
import * as slug from "./slug.js";

Vue.use(VueRouter);

import Home from "../pages/home.vue";
import Class from "../pages/class.vue";
import Group from "../pages/group.vue";
import Subject from "../pages/subject.vue";
import Login from "../pages/login.vue";

const route = (path, component) => {
  return { path, component };
};

const router = new VueRouter({
  mode: "history",

  routes: [
    route(slug.HOME, Home),
    route(slug.LOGIN, Login),
    route(slug.CLASS, Class),
    route(slug.GROUP, Group),
    route(slug.SUBJECT, Subject),
    // {
    //   path: slug.FACILITY,
    //   component: Facility,
    // },
  ],
});

export default router;
