<template>
  <div v-if="true">
    <v-row>
      <v-col cols="12">
        <v-btn
          @click="download"
          color="primary"
          class="mx-auto"
          :loading="excelBtnLoader"
          >Download Excel Report</v-btn
        >
        <v-btn
          @click="downloadImages"
          color="primary"
          class="mx-auto ml-4"
          :loading="zipBtnLoader"
          >Download Student Images</v-btn
        >
      </v-col>
    </v-row>
    <!--Summary  -->
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>Total Applicant: {{ students.length }}</h2>
      </v-col>
      <v-col cols="12" class="text-center">
        <h3>Class Summary</h3>
        <v-chip
          label
          v-for="className in Object.keys(classSummary)"
          :key="className"
          class="mx-2"
        >
          {{ className }} :
          {{ classSummary[className] ? classSummary[className] : 0 }}
        </v-chip>
      </v-col>
      <v-col cols="12" class="text-center">
        <h3>Group Summary</h3>
        <v-chip
          label
          v-for="(groupName, index) in Object.keys(groupSummary)"
          :key="index"
          class="mx-2"
        >
          {{ groupName == "null" ? "Others" : groupName }} :
          {{ groupSummary[groupName] ? groupSummary[groupName] : 0 }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="loadingState">
        <loader />
      </v-col>
      <v-col cols="12" v-else>
        <div class="text-center">
          <v-btn
            :color="filteredOption == 'all' ? 'primary' : 'success'"
            class="mx-2"
            @click="filterStudentByPaymentStatus('all')"
            >All Students ({{ students.length }})
          </v-btn>
          <v-btn
            :color="filteredOption == 'paid' ? 'primary' : 'success'"
            class="mx-2"
            @click="filterStudentByPaymentStatus('paid')"
            >Paid Students ({{ numberOfPaidStudent }})</v-btn
          >
          <v-btn
            :color="filteredOption == 'unpaid' ? 'primary' : 'success'"
            class="mx-2"
            @click="filterStudentByPaymentStatus('unpaid')"
            >Unpaid Students ({{ numberOfUnpaidStudent }})</v-btn
          >
        </div>
        <data-table
          :tableHeaders="headerArray"
          :tableData="filteredStudents"
          @deleteHandler="deleteStudent"
          @editHandler="downloadStudent"
          :editIconValue="'fa-download'"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import FileDownload from "js-file-download";
export default {
  beforeMount() {
    this.getAllStudents();
    // this.getClassList();
  },
  components: {},
  computed: {},
  data() {
    return {
      filteredOption: "all",
      loadingState: false,
      students: [],
      filteredStudents: [],
      excelBtnLoader: false,
      zipBtnLoader: false,
      headerArray: [
        { text: "", value: "actions" },
        { text: "Id", value: "applicationId" },
        { text: "Transaction Id", value: "transactionId" },
        { text: "Name", value: "applicantName" },
        { text: "Class", value: "className" },
        { text: "Group", value: "groupName" },
        { text: "Gender", value: "gender" },
        { text: "Image", value: "imageUrl" },
        { text: "Mobile", value: "mobileNumber" },
        { text: "Religion", value: "religion" },
        { text: "Birth Certificate", value: "birthCertificateNumber" },
        { text: "Blood group", value: "bloodGroup" },
        { text: "DOB", value: "dateOfBirth" },
        { text: "Mail", value: "emailAddress" },
        { text: "Father", value: "fatherName" },
        { text: "F. nid", value: "fatherNidNumber" },
        { text: "F. job", value: "fatherOccupation" },
        { text: "Mother", value: "motherName" },
        { text: "M. nid", value: "motherNidNumber" },
        { text: "M. job", value: "motherOccupation" },
        { text: "Permanent Address", value: "permanentAddress" },
        { text: "Present Address", value: "presentAddress" },
      ],

      classSummary: {},
      groupSummary: {},
      numberOfPaidStudent: 0,
      numberOfUnpaidStudent: 0,
    };
  },
  methods: {
    download() {
      this.excelBtnLoader = true;
      this.$http
        .get(api.DOWNLOAD_EXCEL_REPORT, { responseType: "blob" })
        .then((res) => {
          console.log("Res======>", res.data);
          let data = res.data;
          FileDownload(data, "students.xlsx");
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.excelBtnLoader = false;
        });
    },
    downloadImages() {
      this.zipBtnLoader = true;
      this.$http
        .get(api.DOWNLOAD_IMAGES, { responseType: "blob" })
        .then((res) => {
          console.log("Res======>", res.data);
          let data = res.data;
          FileDownload(data, "StudentsImages.zip");
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.zipBtnLoader = false;
        });
    },
    getAllStudents() {
      this.loadingState = true;
      this.$http
        .get(api.GET_ALL_STUDENTS + "?pageNumber=0&pageSize=10000")
        .then((res) => {
          this.students = res.data.payLoad;
          this.filteredStudents = [...this.students];
          this.createStudentSummary(this.students);
          console.log(this.students);
        })
        .catch((error) => {
          console.log(" Error=======>", error);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },

    getClassList() {
      this.loadingState = true;
      this.$http
        .get(api.CLASS_NAMES)
        .then((res) => {
          console.log("Res==>", res.data);
          this.classNames = res.data.payLoad;
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });

      this.$http
        .get(api.GROUP_NAMES)
        .then((res) => {
          console.log("Res==>", res.data);
          this.groupNames = res.data.payLoad;
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {});
    },
    filterStudentByPaymentStatus(status) {
      if (status == "all") {
        this.filteredStudents = [...this.students];
      } else if (status == "paid") {
        this.filteredStudents = this.students.filter((e) => e.isPaid);
      } else if (status == "unpaid") {
        this.filteredStudents = this.students.filter((e) => !e.isPaid);
      }
      this.filteredOption = status;
    },
    createStudentSummary(students) {
      let classSummary = {};
      let groupSummary = {};
      let numberOfPaidStudent = 0;
      let numberOfUnpaidStudent = 0;
      for (let i = 0; i < students.length; i++) {
        let className = students[i].className;
        let groupName = students[i].groupName;

        classSummary[className]
          ? (classSummary[className] += 1)
          : (classSummary[className] = 1);

        groupSummary[groupName]
          ? (groupSummary[groupName] += 1)
          : (groupSummary[groupName] = 1);

        students[i].isPaid ? numberOfPaidStudent++ : numberOfUnpaidStudent++;
      }
      // console.log("classSummary===>", classSummary.keys(), "groupSummary===>", groupSummary)

      this.classSummary = classSummary;
      this.groupSummary = groupSummary;
      this.numberOfPaidStudent = numberOfPaidStudent;
      this.numberOfUnpaidStudent = numberOfUnpaidStudent;
    },
    deleteStudent(student) {
      console.log("data===>", student);
      if (confirm("Procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.DELETE_STUDENT + "/" + student.applicationId)
          .then((res) => {
            console.log("Res==>", res.data);
            alert("Deleted");
            this.students = this.students.filter(
              (e) => e.applicationId != student.applicationId
            );
          })
          .catch((error) => {
            console.log(" Error=======>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    downloadStudent(student) {
      this.loadingState = true;
      this.$http
        .get(api.DOWNLOAD_FORM + "/" + student.applicationId, {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res.data);
          let data = res.data;
          FileDownload(data, "AdmissionForm.pdf");
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
          alert("error occurred");
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>