// "http://onlineadmissionsystem-env.eba-927impf6.ap-south-1.elasticbeanstalk.com";
// const HOST = "http://143.244.128.66:8080";
const HOST = "https://admission.dcampusbd.com";
// const HOST = "http://localhost:5000";
const PREFIX = "api/admin";
const BASE_URL = `${HOST}/${PREFIX}`;

export const LOGIN = `${HOST}/api/login`;

export const DOWNLOAD_EXCEL_REPORT = `${HOST}/api/v1/get-excel-report`;
export const GET_USER_INFO = `${HOST}/api/v1/get-user-and-institute-info`;
export const GET_INSTITUTE_INFO = `${HOST}/api/v1/institute-info-via-custom-institute-id`; /*/?customInstituteId={customInstituteId} */
export const GET_ALL_STUDENTS = `${HOST}/api/v1/get-applicants-info`;

export const CLASS_NAMES = `${HOST}/api/v1/get-all-classes`; /* /:instituteId */
export const CREATE_CLASS = `${HOST}/api/v1/create-class`;
export const UPDATE_CLASS = `${HOST}/api/v1/update-class-information`;
export const DELETE_CLASS = `${HOST}/api/v1/delete-class?classId=`; /* ?classId= */

export const GROUP_NAMES = `${HOST}/api/v1/get-all-groups`; /* /:instituteId?classId=6 */
export const CREATE_GROUP = `${HOST}/api/v1/create-group`; /* /:classId*/
export const UPDATE_GROUP = `${HOST}/api/v1/update-group`; /* /:groupId*/
export const DELETE_GROUP = `${HOST}/api/v1/delete-group?groupId=`; /* ?groupId=*/

export const SUBJECT_NAMES = `${HOST}/api/v1/get-all-subjects`; /* /:classId?groupId=6 */
export const CREATE_SUBJECT = `${HOST}/api/v1/create-subject`; /* /:groupId*/
export const DELETE_SUBJECT = `${HOST}/api/v1/delete-subject?subjectId=`; /* ?subjectId*/

export const DELETE_STUDENT = `${HOST}/api/v1/studentApplication/delete-application`;
export const DOWNLOAD_FORM = `${HOST}/api/v1/studentApplication/get-applicant-info-pdf`;
export const DOWNLOAD_IMAGES = `${HOST}/api/v1/get-admission-images`;
